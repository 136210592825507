import React, { FC, useEffect, useState } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import lightSurfaceColors from '@sport1/news-styleguide/lightSurfaceColors'
import CardHeadline from '@sport1/news-styleguide/CardHeadline'
import { isPureActive } from '../ThirdParty/Piano/util'
import { CUSTOM_VENDOR_IDS } from '@/utils/consent/consentVendors'
import { useConsentManager } from '@/utils/consent/ConsentInfoProvider'
import { useAdPlacementContext } from '@/context/AdPlacementContext/AdPlacementProvider'

export type PinpollProps = {
    height?: number
    title?: string
    isArticle?: boolean
    hideUnfilled?: boolean
}

const Pinpoll: FC<PinpollProps> = ({ title = 'DEINE MEINUNG', isArticle, hideUnfilled }) => {
    const { consentInfo, getConsentById } = useConsentManager()
    const [isSurvey, setIsSurvey] = useState(true)

    const { adKeys = [] } = useAdPlacementContext()

    useEffect(() => {
        const shouldCheck =
            !isPureActive() &&
            consentInfo.consent_info !== 'off' &&
            getConsentById('custom', CUSTOM_VENDOR_IDS.PINPOLL) &&
            hideUnfilled
        if (shouldCheck) {
            function checkPinpollFilled(callback: Function) {
                const i = setInterval(() => {
                    if (
                        document.querySelector('[data-mode=aipoll].loaded') &&
                        window?.Pinpoll?.embed?.embeds
                    ) {
                        clearInterval(i)
                        callback(window.Pinpoll.embed.embeds.length > 0)
                    }
                }, 1000)
            }
            checkPinpollFilled((a: boolean) => {
                setIsSurvey(a)
            })
        }
    }, [consentInfo.consent_info, getConsentById, setIsSurvey, hideUnfilled])

    if (isPureActive()) return null

    if (consentInfo.consent_info === 'off') {
        return <NonFlexingContainer className="pinpoll-fallback" marginBottom="spacing-9" />
    }

    const dataTopic =
        adKeys[0].toLowerCase() === 'home' ? adKeys[0] : [adKeys[0], adKeys[1]].join(' ')

    return isSurvey && getConsentById('custom', CUSTOM_VENDOR_IDS.PINPOLL) ? (
        <NonFlexingContainer
            className="px-3 sm:px-6 md:px-3 lg:px-0"
            {...(isArticle
                ? {
                      marginBottom: ['spacing-9', 'spacing-9', 'spacing-11'],
                      paddingX: ['spacing-none', 'spacing-none', 'spacing-11'],
                  }
                : {
                      paddingBottom: 'spacing-11',
                  })}
        >
            <NonFlexingContainer
                {...(isArticle
                    ? {
                          padding: 'spacing-9',
                          backgroundColor: lightSurfaceColors['background-tertiary'],
                      }
                    : {})}
            >
                <NonFlexingContainer marginBottom="spacing-6">
                    <CardHeadline
                        {...(!isArticle
                            ? {
                                  cardHeadlineType: [
                                      'cardHeadlineS',
                                      'cardHeadlineM',
                                      'cardHeadlineM',
                                  ],
                              }
                            : {
                                  cardHeadlineType: 'cardHeadlineXS',
                              })}
                        text={title || ''}
                        testID="pinpoll-headline"
                    />
                </NonFlexingContainer>
                <NonFlexingContainer minHeight={250}>
                    <div
                        data-url="www.sport1.de"
                        className="pinpoll"
                        data-mode="aipoll"
                        data-autoplay="6"
                        data-topic={dataTopic}
                    />
                </NonFlexingContainer>
            </NonFlexingContainer>
        </NonFlexingContainer>
    ) : null
}

export default Pinpoll
